.cursus *{
    box-sizing: border-box;
    user-select: text;
}

.cursus{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding-top:20px ;
}

.cursus-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 10px;
}

.cursus-left, .cursus-right{
    width: 90%;
    height: auto;
    display: flex;
    max-width: 800px;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.cursus-title{
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
}

.timeline-container{
    width: 100%;
    height: auto;
    display: flex;
    max-width: 800px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.timeline-container ul{
    list-style-type: none;
    width: 100%;
}

.timeline-container ul li{
    padding: 20px;
    background-color: #525a6f;
    color: white;
    border-radius: 10px;
    margin-bottom: 20px;
}

.timeline-container ul li:last-child{
    margin-bottom: 0px;
}

.timeline-container ul li h1{
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 10px;
    letter-spacing: 1px;
}

.timeline-title span{
    font-size: 15px;
    letter-spacing: 1px;
    color: #02A4E4;
}

.timeline-container ul li p{
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
}

.timeline-container .timeline{  
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    letter-spacing: 2px;
}

.timeline-container .timeline h3{
    font-size: 10px;
    font-weight: 700;  
    color: #02A4E4;
}

@media screen and (min-width : 768px){
    .timeline-container::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 2px;
        background-color: rgb(205, 193, 193);
    }

    .timeline-container ul li{
        width: 50%;
        margin-bottom: 50px;
        position: relative;
    }
    .timeline-container ul li:nth-child(odd) {
        float: left;
        clear: right;
        transform: translateX(-30px);
        border-radius: 20px 0px 20px 20px;
    }
    .timeline-container ul li:nth-child(even) {
        float: right;
        clear: left;
        transform: translateX(30px);
        border-radius: 0px 20px 20px 20px;
    }
    .timeline-container ul li::after{
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        background-color: rgb(205, 193, 193);
        border-radius: 50%;
        top:0;
    }
    .timeline-container ul li:nth-child(odd)::after{
        transform: translate(-50%, -50%);
        right: -50px;
    }
    .timeline-container ul li:nth-child(even)::after{
        transform: translate(-50%, -50%);
        left: -30px;
    }
    .timeline-container ul li:hover::after{
        background-color: #02A4E4;
    }
    .timeline-container .timeline{
        width: 95%;
        position: absolute;
        top: -20px;
        color: black;
    }
}

@media screen and (min-width: 481px){
    .cursus-title{
        font-size: 50px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 20px;
    }
}
