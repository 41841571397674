.toggle{
    position: fixed;
    z-index: 999;
    top: 20px;
    left: 40px;
    height: 50px;
    width: 100px;
    border: 1px solid #eee;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    cursor: pointer;
    background-color: #eee;
}

.toggleimage{
    height: 50px;
    width: 50px;
}

.togglehider{
    position: absolute;
    height: 50px;
    width: 50px;
    left: 50px;
    background-color: #eee;
}