.project {
  position: relative;
  width: 30%;
  height: 40vh;
  border: 1px solid #f3f2f2;
  margin: 10px;
  border-radius: 20px 20px 0px 0px;
  overflow: hidden;
}

.certif {
  position: relative;
  width: 30%;
  height: 40vh;
  border: 5px solid #f3f2f2;
  margin: 10px;
  border-radius: 20px 20px 20px 20px;
  overflow: hidden;
}

.project-style {
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 25px;
  background-color: #f3f2f2;
  position: sticky;
  z-index: 2;
}

.project-style .circle {
  height: 8px;
  width: 8px;
  margin: 0px 3px;
  background-color: white;
  border-radius: 50%;
}

.imagewrapper {
  width: 100%;
}

.certif-text{
  text-shadow: 1px 1px 3px black;
}
.certif-hoover{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #02A4E4;
  font-weight: bolder;
  color: white;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  font-size: large;
  transition: ease-in 0.5s opacity;
}

.certif-hoover:hover{
  opacity: 0.8;
}

.certifimagewrapper img {
  width: 100%;
  height: 100%;
  transition: all 3s ease;
}

.certifimagewrapper:hover img {
  scale: 1.2;
}

.imagewrapper img {
  width: 100%;
  transition: all 5s ease;
}

.imagewrapper:hover img {
  transform: translateY(-75%);
}

.tools-list {
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 10px;
  background-color: rgb(243, 242, 242);
  position: absolute;
  z-index: 2;
  bottom: 0;
  color: black;
}

@media screen and (max-width: 768px) {
  .project {
    width: 40%;
    height: 40vh;
  }
}

@media screen and (max-width: 480px) {
  .certif {
    height: 20vh;
  }
  .certif-hoover{
    font-size: small;
  }
  .project {
    width: 40%;
    height: 25vh;
  }
  .tools-list {
    padding: 1px;
    font-size: 7px;
  }
}
