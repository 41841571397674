.projects{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.projects-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.projects-title h1{
    font-size: 50px;
    font-weight: 400;
    text-align: center;
}

.projects-title p{
    color: #555;
    text-align: center;
}

.projects-github{
    text-decoration: none;
}

.projects-github button{
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 10px;
    text-align: center;
    color: black;
    outline: none;
    border: none;
    box-shadow: 0 0 5px #aaa;
    transition: background-color 1s ease-in-out;
    color: #151515;
    font-weight: 600;
}

.projects-github button:hover{
    background-color: #02A4E4;
}

.projects-list{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@media screen and (max-width: 768px){
    .projects-title h1{
        margin-top: 30px;
    }
    .projects-title p{
        text-align: center;
        padding: 10px;
    }
}

@media screen and (max-width: 480px){
    .projects-title h1{
        font-size: 30px;
        margin-top: 30px;
    }
    .projects-title p{
        text-align: center;
        padding: 10px;
    }
}