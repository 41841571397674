.about {
  height: 100vh;
  display: flex;
}

.about-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-left-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.about-bg {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 70vh;
  width: 80%;
  border-radius: 30px;
  background-color: black;
}

.about-image {
  position: relative;
  height: 70vh;
  width: 80%;
  border-radius: 30px;
  overflow: hidden;
}

.about-imagepic {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.about-right-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 50px 0px 0px;
}

.about-card-upper {
  margin-bottom: 20px;
}

.about-title {
  font-size: 50px;
  font-weight: 400;
  margin: 10px 0;
}

.about-short {
  font-size: 20px;
}

.about-desc {
  margin-top: 15px;
  color: #666;
}

.about-card-lower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.award-image {
  height: 164px;
  width: 164px;
  margin-right: 30px;
}

.award-imagepic {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.award-body {
  line-height: 25px;
}

.award-title {
  margin-bottom: 10px;
}

.award-text {
  box-sizing: border-box;
  padding-right: 20px;
  color: #666;
}

.linkedin-container {
  margin-bottom: 10px;
}

.linkedin-button {
  text-decoration: none;
}

.linkedin-button button {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  color: black;
  outline: none;
  border: none;
  box-shadow: 0 0 5px #aaa;
  transition: background-color 1s ease-in-out;
  background-color: #02A4E4;
  color: #151515;
  font-weight: 600;
}

.linkedin-button button:hover {
  background-color: white;
}

@media screen and (max-width: 900px) {
  .about-right-wrapper{
    padding: 0px 20px 0px 0px;

  }
}

@media screen and (max-width: 480px) {
  .about {
    flex-direction: column;
  }
  .about-left {
    height: 30%;
  }

  .about-left-wrapper {
    height: 100%;
  }
  .about-bg {
    display: none;
  }
  .about-image {
    height: 60%;
  }
  .about-right {
    height: 70%;
  }
  .about-title {
    font-size: 30px;
    text-align: center;
  }
  .about-short {
    text-align: center;
  }
  .about-desc {
    display: none;
  }
  .about-card-lower, .about-card-upper  {
    width: 90%;
    margin: auto;
  }
  .linkedin-container {
    text-align: center;
  }
}
