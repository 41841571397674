.contact{
    user-select: none;
    font-size: 20px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.contact-bg{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 20px;
    background-color: #02A4E4;
}

.contact-wrapper{
    margin: 40px;
    height: 100%;
    width: calc(100%-80px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-left{
    flex: 1;
    margin-left: 40px ;
}

.contact-right{
    flex: 1;
}

.contact-left h1{
    font-size: 80px;
    font-weight: 900;
}

.contact-info>div{
    margin: 50px 0;
    display: flex;
    align-items: center;
    color: #555;
}

.contact-info img{
    height: 40px;
    width: 40px;
    margin-right: 30px;
}

.contact-info a{
	color: #555;
}

.contact-info a:hover{
	color: #02A4E4;
}

.contact-intro{
    color: #555;
    margin-bottom: 20px;
    width: 90%;
}

.contact-question{
    font-weight: bold;
    color: black;
}

.contact-form input{
    font-size: 20px;
    padding-left: 10px;
    width: 60%;
    height: 50px;
    margin: 10px 0 0 0;
    border: none;
    border-bottom: 1px solid black;
}

.contact-form input:focus{
    outline: none;
}

.contact-form p{
    color: red;
    font-size: 12px;
}

.contact-form textarea{
    width: 80%;
    padding: 20px;
    font-size: 20px;
    height: 70px;
    margin: 10px 0 0 0;
}

.contact-form button{
    display: block;
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
    background-color: white;
    color: #02A4E4;
    border: 1px solid black;
}

.contact-form button:hover{
    background-color: #02A4E4;
    color: white;
    border: 1px solid #02A4E4;
}

.send-success{
    color: #02A4E4;
    margin-top: 10px;
}


@media screen and (max-width: 1200px){
    .contact-left h1{
        font-size: 50px;
        font-weight: 900;
    }
}

@media screen and (max-width : 1000px) and (min-width : 481px){
    .contact-wrapper{
        margin: 0;
    }

    .contact-left h1{
        font-size: 50px;
        text-align: center;
    }

    .contact-info>div{
        margin: 15px 0;
        font-size: 15px;
    }

    .contact-info img{
        height: 40px;
        width: 40px;
        margin-right: 20px;
    }

    .contact-intro{
        font-size: 15px;
    }

    .contact-form input{
        font-size: 15px;
    }

    .contact-form textarea{
        height: 50px;
        font-size: 15px;
    }

    .contact-form button{
        padding: 5px 10px;
        font-size: 20px;
        font-weight: 600;
    }
    .send-success{
        font-size: 14px;
    }
}


@media screen and (max-width : 480px){
    .contact{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
    }
    .contact-wrapper{
        height: auto;
        padding-top: 10px;
        flex-direction: column;
    }

    .contact-left{
        padding-bottom: 20px;
        flex: auto;
        height: auto;
    }

    .contact-left h1{
        padding-bottom: 20px;
        font-size: 35px;
        text-align: center;
    }

    .contact-wrapper{
        margin: 0px;
        flex-direction: column;
    }
    .contact-info>div{
        margin: 5px 0;
        font-size: 15px;
    }
    .contact-intro{
        display: none;
    }
    .contact-right{
        height: 60%;
        flex: auto;
        margin-left: 40px;
    }
    .contact-form{
        display: flex;
        flex-direction: column;
        width: 90%;
    }
    .contact-form input{
        font-size: 15px;
        width: 80%;
        height: 20px;
        margin: 10px 10px;
    }

    .contact-form textarea{
        height: 50px;
        font-size: 15px;
    }

    .contact-form button{
        display: block;
        width: 50%;
        padding: 5px 10px;
        font-size: 20px;
        font-weight: 600;
    }
    .send-success{
        font-size: 12px;
    }
}