.intro{
    display: flex;
    height: 100vh;
    margin-bottom: 5px;
}

.intro-name{
    padding-bottom: 15px;
}

.intro-desc{
    padding-right: 60px;
    padding-top: 15px;
}

.download-resume{
    position: fixed;
    top: 20px;
    left: 150px;
    text-decoration: none;
    z-index: 999;
}

.download-resume button{
    cursor: pointer;
    height: 50px;
    width: 120px;
    border-radius: 10px;
    text-align: center;
    color: black;
    outline: none;
    border: none;
    box-shadow: 0 0 5px #aaa;
    transition: background-color 1s ease-in-out;
    color: #151515;
    font-weight: 600;
}

.download-resume button:hover{
    background-color: #02A4E4;
}

.intro-left{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-right{
    flex: 1;
    position: relative;
}

.intro-left-wrapper{
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.intro-text{
    font-size: 30px;
    font-weight: 300;
}

.intro-title{
    height: 50px;
    overflow: hidden;
}

.intro-name{
    font-size: 60px;
    line-height:  100%;
}

.intro-title-wrapper{
    height: 100%;
    animation: move 5s ease-in-out infinite alternate;
}

@keyframes move {
    100%{
        transform: translateY(-100px);
    }
}

.intro-title-item{
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #02A4E4;
    display: flex;
    align-items: center;
}

.intro-scroll{
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20px;
}

.intro-image{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    object-fit: contain;
    position: absolute;
}

.intro-image img{
    max-height: 100%;
    width: 100%;
    object-fit: contain;
}

.intro-background{
    clip-path: polygon(50% 0%, 100% 0, 100% 43%, 100% 78%, 100% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);
    height: 100%;
    width: 100%;
    background-color: #02A4E4;
    position: absolute;
    top: 0;
    right: 0;
}




@media screen and (max-width : 880px) and (min-width : 768px){

    .intro-name{
        font-size: 45px;
    }

    .intro-title-item{
        display: flex;
        justify-content: center;
        font-size: 20px;
    }

    .intro-left-wrapper{
        padding-right: 20px;
    }
}

@media screen and (max-width : 768px){
    .intro{
        flex-direction: column;
    }
    .intro-desc{
        display: none;
    }
    .intro-title-wrapper{
        text-align: center;
        width: 100%;
        padding: 0;
    }
    .intro-left{
        flex: auto;
        height: 40%;
        margin-top: 50px;
    }
    .intro-right{
        flex: auto;
        height: 60%;
    }
    .intro-text{
        text-align: center;
    }

    .intro-name{
        text-align: center;
        font-size: 35px;
        font-weight: 500;
    }

    .intro-title-item{
        display: flex;
        justify-content: center;
        font-size: 20px;
    }
}

@media screen and (max-width : 320px){

    .intro-text{
        font-size: 15px;
    }

    .intro-name{
        font-size: 25px;
    }
}